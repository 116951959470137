import React, { useEffect, useState } from 'react'
import { Head, Footer } from '~/components'
import { Link, PageProps } from 'gatsby'
import { useContext } from '~/hooks'
import * as st from '~/assets/styl/PageDefault.module.styl'
import img from '~/assets/img/img-404.png'
import cn from 'classnames'

const NotFound = ({ location }: PageProps) => {
  const { setLoading } = useContext()
  const [ready, setReady] = useState(false)
  useEffect(() => {
    setLoading(false)
    setReady(true)
  }, [])

  return ready ? (
    <>
      <Head location={location} />
      <main className={cn(st.core, st.notFound)}>
        <section className={cn(st.imgText, st.orange)}>
          <img src={img} alt="" />
          <div>
            <h1>Erro 404</h1>
            <p>
              <strong>Página não encontrada.</strong>
            </p>
            <p>
              A página que você tentou acessar não existe ou está fora do ar
              temporariamente.
            </p>
            <p>Confira o endereço e tente novamente.</p>
            <Link to="/">Ir para Home</Link>
          </div>
        </section>
      </main>
      <Footer location={location} />
    </>
  ) : (
    <Head location={location} />
  )
}

export default NotFound
